<template>
  <div class="material">
    <a-page-header title="物料管理" :ghost="false">
      <template #extra>
        <a-button type="primary" @click="sortModal">导入分类</a-button>
        <a-button type="primary" @click="addsortModal">新增分类</a-button>
      </template>
    </a-page-header>
    <div>
      <a-row :gutter="[24, 16]" class="info-row">
        <a-col :span="4">
          <categoryname @postKey="getKey" ref="categoryname" />
        </a-col>
        <a-col :span="20">
          <div class="material-info">
            <a-row style="margin-bottom: 20px">
              <a-col :span="12">
                <a-input
                  v-model="search"
                  placeholder="物料编码、物料名称、规格型号"
                  style="width: 300px; margin-right: 15px"
                /><a-button type="primary" @click="onSearch()">查询</a-button>
              </a-col>
              <a-col :span="12">
                <div class="btn-list">
                  <a-button type="primary" @click="materielModal"
                    >导入物料</a-button
                  >
                  <a-button type="primary" @click="materialNew"
                    >新增物料</a-button
                  >
                  <a-button type="primary" @click="onDelete">删除</a-button>
                </div>
              </a-col>
            </a-row>
            <a-table
              :row-selection="rowSelection"
              :columns="columns"
              rowKey="id"
              ref="table"
              :data-source="getMaterialDataList"
              :pagination="pagination"
              @change="handleTableChange"
              bordered
              :scroll="{ x: 2000 }"
              class="table-box"
            >
              <a
                slot="fullCode"
                slot-scope="text, record"
                @click="goEdit(record.id)"
                >{{ text }}</a
              >
              <!-- <span slot="customTitle"><a-icon type="smile-o" /> Name</span> -->

              <a slot="action" slot-scope="text, record">
                <a-dropdown :trigger="['click']">
                  <a
                    class="ant-dropdown-link"
                    @click="(e) => e.preventDefault()"
                    >操作</a
                  >
                  <a-menu slot="overlay" class="uplist">
                    <a-menu-item key="1">
                      <a @click="goEdit(record.id)"
                        ><a-icon type="edit" />编辑</a
                      >
                    </a-menu-item>
                    <a-menu-item key="2" @click="showConfirm(record)">
                      <a-icon type="delete" />删除
                    </a-menu-item>
                    <a-menu-item key="3">
                      <a @click="historyModal(record)"
                        ><a-icon type="history" />历史版本</a
                      >
                    </a-menu-item>
                    <a-menu-item key="4">
                      <a @click="goBom(record)"
                        ><a-icon type="file-text" />物料BOM</a
                      >
                    </a-menu-item>
                    <!-- <a-menu-item key="5">
                      <a><a-icon type="shopping-cart" />加入购物车</a>
                    </a-menu-item>
                    <a-menu-item key="6">
                      <a><a-icon type="cloud-server" />产品分销</a>
                    </a-menu-item> -->
                  </a-menu>
                </a-dropdown>
              </a>
            </a-table>
          </div>
        </a-col>
      </a-row>
    </div>

    <!-- 导入分类 -->
    <a-modal
      title="导入分类"
      :visible="sortvisible"
      :confirm-loading="sortconfirmLoading"
      @ok="sorthandleOk"
      @cancel="sorthandleCancel"
      okText="开始上传"
      :cancel-button-props="{ style: { display: 'none' } }"
      width="50%"
    >
      <importsort :sortvisible="sortvisible" @uploadSuccess="sortSuccess" />
    </a-modal>

    <!-- 新增分类 -->
    <addsort
      :visible="addsortCtr.addsortvisible"
      :form="addsortCtr.form"
      @categorynameQuery="categorynameQuery"
      @submited="
        () => {
          this.addsortCtr.addsortvisible = false;
          this.$refs['sortTree'].getMaterialClassificationTreeData();
        }
      "
      @cancel="
        () => {
          this.addsortCtr.addsortvisible = false;
        }
      "
      ref="sortTree"
    ></addsort>

    <!-- 导入物料 -->
    <a-modal
      title="导入物料"
      :visible="materielvisible"
      :confirm-loading="materielconfirmLoading"
      @ok="materielhandleOk"
      @cancel="materielhandleCancel"
      okText="开始上传"
      :cancel-button-props="{ style: { display: 'none' } }"
      width="80%"
    >
      <importmateriel
        :materielvisible="materielvisible"
        @uploadmaterialSuccess="materialSuccess"
        @materialClassificationId="materialClassificationId"
      />
    </a-modal>

    <!-- 历史版本 -->
    <a-modal
      v-model="historyvisible"
      title="历史版本比较"
      @ok="historyhandleOk"
      :ok-button-props="{ style: { display: 'none' } }"
      :cancel-button-props="{ style: { display: 'none' } }"
      width="50%"
      class="history"
      :destroyOnClose="true"
    >
      <versioncompare :record="currentRecord" />
    </a-modal>
  </div>
</template>

<script>
// 左侧栏树状
import categoryname from "./components/categoryname";
// 导入分类
import importsort from "./components/importsort";
// 新增分类
import addsort from "./components/addsort";
// 导入物料
import importmateriel from "./components/importmateriel";
// 历史版本对比
import versioncompare from "./components/versioncompare";

import { getMaterialList, deleteMaterial, addMaterial } from "@/api/material";
import {
  addMaterialClassification,
  getMaterialClassification,
} from "@/api/materialclassification";
import columns from "./columns";

export default {
  components: {
    categoryname,
    importsort,
    importmateriel,
    addsort,
    versioncompare,
  },
  data() {
    return {
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      SkipCount: 0, //从总数据的第几条数据开始取数据
      MaxResultCount: 10, //每页中显示数据的条数
      search: "",
      clickKey: "",
      searchData: [], //搜索后的展示数据
      getMaterialDataList: [], //原始展示数据
      columns: [...columns],
      selectedCategory: {},
      // 导入分类
      sortvisible: false,
      sortconfirmLoading: false,
      sortImportData: [],
      // 新增分类
      addsortCtr: {
        form: {},
        addsortvisible: false,
      },

      // 导入物料
      materielvisible: false,
      materielconfirmLoading: false,

      // 历史版本
      historyvisible: false,

      // 当前选中物料分类ID
      materialClassID: "",
      //当前操作选中行
      currentRecord: {},
      //选中行主键集合
      selectRowKeys: [],
      classificationID: null,
    };
  },
  created() {},
  methods: {
    /** 刷新分类树 */
    categorynameQuery() {
      var categoryname = this.$refs.categoryname;
      if (categoryname) categoryname.getDataList();
    },
    getKey(data) {
      this.pagination.current = 1;
      this.clickKey = data;
      this.onSearch(data, 0, 10);
      getMaterialClassification(data).then((res) => {
        this.selectedCategory = res;
        this.afterChangeCategory();
      });
      this.materialClassID = data;
    },
    afterChangeCategory() {
      const temp = [...columns];
      console.info(this.selectedCategory.attributes)
      const atts = this.selectedCategory.attributes.filter(
        (x) => x.classify === 1&&x.typeCode!=='LongText'
      );
      console.info(atts)
      for (let index = 0; index < atts.length; index++) {
        const element = atts[index];
        if (!element.enabled) continue;
        temp.splice(temp.length - 2, 0, {
          title: element.name,
          customRender: function (text, record) {
            const p = record["extraProperties"] || {};
            const cp = p.customeProperty || {};
            const valObj = cp.find((x) => x.Name === element.name) || {};
            return valObj.Value || "";
          },
          ellipsis: true,
        });
      }
      this.columns = [...temp];
      this.$refs["table"].$forceUpdate();
    },
    getDataList(params) {
      const _this = this;
      getMaterialList(params)
        .then(function (result) {
          console.log(result);
          _this.getMaterialDataList = result.items;
          _this.pagination.total = result.totalCount;
        })
        .catch(function (error) {});
    },
    //分页
    handleTableChange(pagination) {
      console.log(pagination);
      this.pagination.current = pagination.current; //当前第几页
      this.pagination.pageSize = pagination.pageSize;
      this.SkipCount = (pagination.current - 1) * pagination.pageSize; //从总数据的第几条数据开始取数据
      this.MaxResultCount = pagination.pageSize; //一页多少条
      this.onSearch(this.clickKey, this.SkipCount, this.MaxResultCount);
    },
    //查询
    onSearch(classificationID, SkipCount, MaxResultCount) {
      const _this = this;
      var search = _this.search;
      var ClassificationID = classificationID || this.clickKey;
      let params = {
        Filter: search,
        ClassificationID,
        SkipCount,
        MaxResultCount,
      };
      _this.getDataList(params);
    },
    //删除
    showConfirm(record) {
      let _this = this;
      this.$confirm({
        title: "确认删除" + record.name + "吗?",
        onOk() {
          let cancelId = record.id;
          deleteMaterial({ ids: [cancelId] }).then((res) => {
            _this.$message.success("删除成功");
            _this.onSearch(
              _this.clickKey,
              _this.SkipCount,
              _this.MaxResultCount
            );
          });
        },
        onCancel() {},
      });
    },
    //删除所有选中行
    onDelete() {
      if (this.selectRowKeys.length <= 0) {
        this.$message.warn("请先选择待删除行");
        return;
      }

      let _this = this;
      this.$confirm({
        title: "确认删除所选数据吗?",
        onOk() {
          deleteMaterial({ ids: _this.selectRowKeys }).then((res) => {
            _this.$message.success("删除成功");
            _this.onSearch(
              _this.clickKey,
              _this.SkipCount,
              _this.MaxResultCount
            );
          });
        },
        onCancel() {},
      });
    },
    // 导入分类
    sortModal() {
      this.sortvisible = true;
    },
    sortSuccess(data) {
      this.sortImportData = data;
    },
    sorthandleOk(e) {
      let _this = this;
      console.log(this.sortImportData);
      addMaterialClassification(this.sortImportData)
        .then(function (result) {
          _this.$message.success("添加成功");
          console.log(result);
          _this.sortvisible = false;
          _this.sortconfirmLoading = false;
          _this.categorynameQuery();
        })
        .catch(function (error) {});
    },
    sorthandleCancel(data) {
      this.sortvisible = false;
    },

    // 新增分类
    addsortModal() {
      this.addsortCtr.form = {};
      this.addsortCtr.addsortvisible = true;
    }, //获取物料表格中的数据
    materialSuccess(data) {
      this.sortImportData = data;
    },
    materialClassificationId(data) {
      this.classificationID = data;
    },
    // 导入物料
    materielModal() {
      this.materielvisible = true;
    }, //导入物料-保存
    materielhandleOk(e) {
      let _this = this;
      console.log(this.sortImportData);
      console.log(this.classificationID);
      if (this.classificationID == "" || this.classificationID == null) {
        _this.$message.info("请选择物料分类");
        return;
      }
      var material = {};
      material.materialExcelToListDtos = this.sortImportData;
      material.classificationID = this.classificationID;
      addMaterial(material)
        .then(function (result) {
          console.log(result);
          _this.$message.success("添加成功");
          _this.materielfirmLoading = true;
          _this.materielvisible = false;
          _this.categorynameQuery();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    materielhandleCancel(e) {
      this.materielvisible = false;
    },

    // 历史版本
    historyModal(record) {
      this.historyvisible = true;
      this.currentRecord = record;
    },
    historyhandleOk(e) {
      this.historyvisible = false;
    },

    //跳转到详情页
    goEdit(id) {
      this.$router.push({ path: `/MaterialManagement/edit/${id}` });
    },
    //跳转到物料BOM页
    goBom(record) {
      this.$router.push({
        name: "BOMManage",
        params: record,
      });
    },
    //新增物料
    materialNew() {
      if (this.materialClassID == "") {
        this.$message.info("请先选择物料分类");
        return;
      }
      this.$router.push({
        path: `/MaterialManagement/new/${this.materialClassID}`,
      });
    },
  },

  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectRowKeys = selectedRowKeys;
        },
        getCheckboxProps: (record) => ({
          props: {
            // disabled: record.name === 'Disabled User',
            name: record.name,
          },
        }),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.material-info {
  background: #ffffff;
  margin-top: 20px;
  padding: 10px;
}

.info-row {
  margin: 0 !important;
}

.btn-list {
  text-align: right;
}

.btn-list .ant-btn {
  margin-right: 15px;
}

.btn-list .ant-btn:last-child {
  margin-right: 0;
}

.uplist .anticon {
  color: #1890ff;
  margin-right: 10px;
}

.addsort ::v-deep.ant-modal-footer,
.history ::v-deep.ant-modal-footer {
  display: none;
}
// .table-box ::v-deep.ant-table-tbody > tr > td {
//   word-break: keep-all;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
</style>
