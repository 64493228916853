import {
  postRequest,
  getRequest,
  deleteRequest,
  updateRequest,
} from "@/utils/http";

/**
 * 查询BOM目录
 * @param {*} param
 * @returns
 */
export const getcatagory = (param) =>
  getRequest(`/production/bom/getcatagory`, param);

/**
 * 查询BOM列表
 * @param {*} param
 * @returns
 */
export const getList = (param) => postRequest(`/production/bom/getList`, param);

/**
 * 添加物料列表
 * @param {*} param
 * @returns
 */
export const getTinyList = (param) =>
  getRequest(`/production/material/getTinyList`, param);

// 添加物料
export const create = (param) => postRequest(`/production/bom/create`, param);

// 替代料列表
export const getReplaces = (params) =>
  getRequest(`/production/bom/getReplaces`, params);

// 添加替代料
export const addReplaceBom = (param) =>
  postRequest(`/production/bom/addReplaceBom`, param);

// 删除物料BOM/删除替代料BOM
export const deleteBOM = (id, params) =>
  deleteRequest(`/production/bom/${id}`, params);

// 上方下方添加物料
export const insertbom = (param) =>
  postRequest(`/production/bom/insertbom`, param);

// 移动BOM
export const movebom = (param) => postRequest(`/production/bom/movebom`, param);

// 更新BOM/替代料用量
export const update = (param) => updateRequest(`/production/bom/update`, param);

// 切换版本
export const swichVersion = (param) =>
  postRequest(`/production/bom/swichVersion`, param);

// 获取历史版本BOM
export const getChildBom = (param) =>
  getRequest(`/production/bom/getChildBom`, param);

// 获取历史版本信息
export const getVersionList = (params) =>
  getRequest(`/production/material/getVersionList`, params);

// 替代料切换版本
export const switchReplaceVersion = (params) =>
  updateRequest(`/production/bom/switchReplaceVersion?id=${params.id}&materialId=${params.materialId}`);
