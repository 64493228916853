export const isTelephone = (rule, value, callback) => {
  const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
  if (!value) {
    callback(new Error("请输入手机号"));
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确的电话号码"));
    } else {
      callback();
    }
  }
};

export const isEmail = (rule, value, callback) => {
  const reg =
    /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
  if (!value) {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入正确的邮箱号"));
    } else {
      callback();
    }
  }
};

export const isNumOrLetters = (rule, value, callback) => {
  const reg =
    /^[A-Za-z0-9]+$/;
  if (!value) {
    callback();
  } else {
    if (!reg.test(value)) {
      callback(new Error("请输入数字或字母"));
    } else {
      callback();
    }
  }
};
