import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";

/**
 * 查询物料分类集合
 * @param {*} param
 * @returns
 */
export const getMaterialClassificationList = (param) =>
  getRequest(`/production/material-classification/getList`, param);
/**
 * 查询物料版本集合
 * @param {*} param
 * @returns
 */
export const getVersionDownListDown = (param) =>
  getRequest(`/production/material/getDropdownList`, param);
/**
 * 查询物料单位集合
 * @param {*} param
 * @returns
 */
export const getUnitDownListDown = (param) =>
  getRequest(`/common/code/getListByCodeKeyPath/${param}`);
/**
 * 查询物料分类左侧树状
 * @param {*} param
 * @returns
 */
export const getMaterialClassificationTreeData = (param) =>
  getRequest(`production/material-classification/getTreeData`, param);
/**
 * 查询单个物料分类明细
 * @param {物料id} param
 * @returns
 */
export const getMaterialClassification = (param) =>
  getRequest(`/production/material-classification/${param}`);
/**
 * 新建物料分类
 * @param {*} param
 * @returns
 */
export const createMaterialClassification = (param) =>
  postRequest(`/production/material-classification/create`, param);
/**
 * 删除物料分类
 * @param {*} param
 * @returns
 */
export const deleteMaterialClassification = (param) =>
  deleteRequest(`/production/material-classification/${param}`);
/**
 * 更新物料分类
 * @param {*} param
 * @returns
 */
export const updateMaterialClassification = (param) =>
  updateRequest(`/production/material-classification/update`, param);
/**
 * 导入分类
 * @param {*} param
 * @returns
 */
export const importExcelClassification = (param) =>
  postRequest(`/production/material-classification/importExcel`, param);
/**
 * 导入分类上传
 * @param {*} param
 * @returns
 */
export const addMaterialClassification = (param) =>
  postRequest(
    `/production/material-classification/addMaterialClassification`,
    param
  );

/**获取下拉数据 */
export const getDropdownList = (param) =>
  getRequest(`/production/material-classification/dropdown`, param);
/**
 * 查询物料来源集合
 * @param {*} param
 * @returns
 */
export const getSourceDownList = (param) =>
  getRequest(`/common/code/getListByCodeKeyPath/${param}`);
