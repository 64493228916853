<template>
  <div class="importsort">
    <h3>选择文件</h3>
    <a-upload-dragger
      name="file"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      @change="handleChange"
      :pagination="pagination"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        可以直接拖拽上传，或者
        <span>选择文件</span>、<span>选择文件夹</span>进行上传
      </p>
    </a-upload-dragger>
    <a-row class="info-row">
      <a-col :span="12">
        <h3>导入明细</h3>
      </a-col>
      <a-col :span="12">
        <a
          class="ant-btn-primary ant-btn"
          href="/classificationTemplate.xlsx"
          download="classificationTemplate.xlsx"
          >模板下载</a
        >
        <a-button type="primary" @click="clearClick">清空列表</a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="importExceldata"
      :rowKey="(row, index) => index"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="text, record, index">
        <a-space :size="20">
          <a
            ><a-icon
              type="delete"
              :style="{ fontSize: '26px' }"
              @click="rowClick(index)"
          /></a>
        </a-space>
      </template>
    </a-table>
  </div>
</template>
<script>
import { importExcelClassification } from "@/api/materialclassification";
export default {
  props: {
    sortvisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      importExceldata: [],
      columns: [
        {
          title: "序号",
          dataIndex: "order",
        },
        {
          title: "分类名称",
          dataIndex: "name",
        },
        {
          title: "分类编码",
          dataIndex: "code",
        },
        {
          title: "上级分类",
          dataIndex: "categoryParent",
        },
        {
          title: "物料编码开头",
          dataIndex: "fullCode",
        },
        {
          title: "物料编码规则",
          dataIndex: "incrementalType",
        },
        {
          title: "删除",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],

      fileList: [],

      BASEURL: process.env.VUE_APP_API_BASE_URL,

      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
    };
  },
watch: {
    sortvisible(val) {
      console.log(val)
      if (!val) {
        this.importExceldata = [];
      }
    },
  },
  methods: {
    //分页
    handleTableChange(pagination) {
      console.log(pagination);
      this.pagination.current = pagination.current; //当前第几页
      this.pagination.pageSize = pagination.pageSize;
      this.SkipCount = (pagination.current - 1) * pagination.pageSize; //从总数据的第几条数据开始取数据
      this.MaxResultCount = pagination.pageSize; //一页多少条
    },

    beforeUpload(file) {
      console.log("file", file);

      this.fileList = [...this.fileList, file];
      return false;
    },
    rowClick(index) {
      let pageSize = this.pagination.pageSize;
      let current = this.pagination.current - 1;
      let num = current * pageSize + index;
      this.importExceldata.splice(num, 1);

      this.fileList.splice(num, 1);

      this.pagination.current = 1;
      this.$emit("uploadSuccess", this.importExceldata);
    },

    handleChange(info) {
      console.log("info", info);
      const formData = new FormData();
      formData.append("file", info.file);

      importExcelClassification(formData).then((res) => {
        console.log(res);
        this.importExceldata = res.map((item, index) => {
          return Object.assign(item, { order: index + 1 });
        });
        console.log(this.importExceldata);
        this.$emit("uploadSuccess", res);
      });
    },

    clearClick() {
      console.log("clearClick");
      this.importExceldata = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-upload-text span {
  color: #1890ff;
}

.importsort h3 {
  margin-bottom: 20px;
}

.info-row {
  margin: 20px 0;
}

.info-row .ant-btn {
  margin-right: 15px;
}

.info-row .ant-btn:last-child {
  margin-right: 0;
}

.info-row .ant-col-12:nth-child(2) {
  text-align: right;
}
</style>
