import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";

/** 查询数据字典集合 */
export const getCodeList = (parm) => getRequest(`/common/code/getList`, parm);
/** 根据CodeKeyPath查询所有子级字典 */
export const getListByCodeKeyPath = (codeKeyPath) =>
  getRequest(`/common/code/getListByCodeKeyPath/${codeKeyPath}`);
/** 查询数据字典集合(下拉选择器) */
export const getCodeIdList = (id, parm) =>
  getRequest(`/common/code/getList/${id}`, parm);
/**查询单条数据字典 */
export const getCode = (id) => getRequest(`/common/code/${id}`);
/** 新建数据字典 */
export const createCode = (parm) => postRequest(`/common/code/create`, parm);
/** 删除数据字典 */
export const deleteCode = (id) => deleteRequest(`/common/code/${id}`);
/** 修改数据字典 */
export const updateCode = (parm) => updateRequest(`/common/code/update`, parm);
/**
 * 获取下拉数据
 * @param {Object} param 参数
 * @returns Promise
 */
export const getDowndownAsync = (
  param = {
    filter: "",
    maxResultCount: 100,
    sorting: "CodeName",
    parentCodeKeyPath: "",
  }
) =>
  getRequest(
    `/common/code/dropdown`,
    Object.assign(
      { filter: "", maxResultCount: 100, sorting: "CodeName" },
      param
    )
  );
