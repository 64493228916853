<template>
  <a-modal
    title="编辑分类"
    :visible="visible"
    @ok="addsorthandleOk"
    :destroyOnClose="true"
    width="40%"
    :okText="'确定'"
    @cancel="$emit('cancel')"
  >
    <a-form-model
      ref="registerForm"
      layout="horizontal"
      :rules="rules"
      :model="registerForm"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
      labelAlign="left"
    >
      <a-form-model-item label="分类名称" prop="name">
        <a-input
          type="text"
          placeholder="分类名称"
          v-model="registerForm.name"
        />
      </a-form-model-item>
      <a-form-model-item label="上级分类" prop="parentID">
        <a-tree-select
          :defaultValue="registerForm.parentID"
          :tree-data="treeData"
          filterTreeNode
          placeholder="请选择上级分类"
          disabled
          @select="treeDataSelect"
          :dropdown-style="{ maxHeight: '350px', overflow: 'auto' }"
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="分类编码" prop="code">
        <a-input
          type="text"
          placeholder="分类编码"
          disabled
          v-model="registerForm.code"
        />
      </a-form-model-item>
      <a-form-model-item label="物料编码规则" prop="openingLogo">
        <a-input
          type="text"
          placeholder="开头标识"
          disabled
          v-model="registerForm.openingLogo"
          style="width: 47%"
        /><span> + </span
        ><a-select
          placeholder="递增规则"
          :key="registerForm.incrementalType"
          :default-value="registerForm.incrementalType"
          style="width: 48%"
        >
          <a-select-option
            v-for="item of materialIncrementalEnum"
            :value="item.id"
            :key="item.id"
            :disabled="whenChangeMaterialIncrementalEnumdisabled(item)"
            @click="whenChangeMaterialIncrementalEnum(item)"
            >{{ item.codeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 23 }">
        <div class="AS-collapse">
          <a-collapse
            accordion
            expandIconPosition="right"
            @change="collapseClick"
          >
            <a-collapse-panel header="物料属性设置">
              <a-table
                :columns="columns"
                :data-source="dataSource"
                rowKey="key"
                :pagination="false"
              >
                <template slot="action" slot-scope="text, record">
                  <a-icon
                    type="minus-circle"
                    class="out"
                    :visible="record.visible"
                    @click="outclick(record)"
                  />
                </template>
                <a-form-item
                  :label-col="{
                    xs: { span: 0 },
                    sm: { span: 0 },
                  }"
                  :wrapper-col="{
                    xs: { span: 24 },
                    sm: { span: 24 },
                  }"
                  style="margin-bottom: 0px"
                  :validate-status="getFildStatus(text.key).validateStatus"
                  :help="getFildStatus(text.key).errorMsg"
                  slot="name"
                  slot-scope="text, record"
                >
                  <a-input
                    placeholder="请输入属性名称"
                    v-model="text.name"
                    style="width: 100%"
                    :disabled="record.enabledReadonly"
                    @focus="handleChanges(record)"
                    @blur="nameChange(record)"
                    :maxLength="10"
                  />
                </a-form-item>
                <template slot="type" slot-scope="text, record">
                  <a-select
                    v-model="record.type"
                    :disabled="record.typeReadonly"
                    @change="handleChanges(record)"
                  >
                    <a-select-option
                      v-for="item in Codelist"
                      :value="item.id"
                      :key="item.id"
                      >{{ item.codeName }}
                    </a-select-option>
                  </a-select>
                </template>
                <template slot="checkboxEnable" slot-scope="text, record">
                  <a-checkbox
                    :checked="record.enabled"
                    :disabled="record.enabledReadonly"
                    @change="enabledChange(record)"
                  ></a-checkbox>
                </template>
                <template slot="checkboxRequired" slot-scope="text, record">
                  <a-checkbox
                    :checked="record.required"
                    :disabled="record.enabledReadonly"
                    @change="requiredChange(record)"
                  ></a-checkbox>
                </template>
              </a-table>
              <a @click="addhang">添加自定义字段</a>
              <div class="addOptions" v-show="ishide">
                <a-row>
                  <a-col :span="5">
                    <span @click="addoptions">添加选择项</span>
                  </a-col>
                  <a-col :span="19">
                    <div
                      v-for="item in normaladd"
                      :key="item.index"
                      class="addblock"
                    >
                      <a-icon
                        type="close-square"
                        @click="closeSquareClick(item.index)"
                      />
                      <a-input
                        placeholder="请输入项内容"
                        v-model="item.value"
                        style="width: 300px"
                      />
                    </div>
                  </a-col>
                </a-row>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
/**
 * 物料属性名称验证规则
 * @param {String} value 物料属性名称值
 * @param {arrty} normaladd 物料属性选择项添加项
 */
function validateNameValue(value, normaladd) {
  if (value) {
    var normaladdname = normaladd.filter((item) => item.name == value);
    if (normaladdname.length > 1)
      return {
        validateStatus: "error",
        errorMsg: "物料属性名称不能相同",
      };
    return {
      validateStatus: "success",
      errorMsg: "",
    };
  }
  return {
    validateStatus: "error",
    errorMsg: "请输入物料属性名称",
  };
}
import {
  getMaterialClassificationTreeData,
  updateMaterialClassification,
} from "/src/api/materialclassification.js";
import { isNumOrLetters } from "@/utils/tools";
import { getListByCodeKeyPath } from "@/api/code.js";
const columns = [
  {
    title: "",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
  {
    title: "属性名称",
    key: "name",
    width: "200px",
    scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    title: "类型",
    dataIndex: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "是否启用",
    dataIndex: "enabled",
    align: "center",
    scopedSlots: { customRender: "checkboxEnable" },
  },
  {
    title: "是否必填",
    dataIndex: "required",
    align: "center",
    scopedSlots: { customRender: "checkboxRequired" },
  },
];

export default {
  props: {
    visible: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
    incrementalTypeId: { type: String, default: "" },
  },
  /** 组件生命周期组件创建后事件 */
  created() {
    this.getMaterialClassificationTreeData();
    getListByCodeKeyPath("HS_MaterialAttributeType")
      .then((res) => {
        console.log(res);
        this.Codelist = res;
      })
      .catch(() => {
        this.$message.error("获取行业信息失败，请重试～！");
      });
    getListByCodeKeyPath("HS_IncrementalType")
      .then((res) => {
        this.materialIncrementalEnum = res;
      })
      .catch(() => {
        this.$message.error("获取行业信息失败，请重试～！");
      });
  },

  data() {
    /** 开头标识验证规则 */
    let openingLogoValidator = (rule, value, cbfn) => {
      var _this = this;
      if (!value || !_this.registerForm.openingLogo)
        cbfn("物料编码规则开头标识不能为空");
      if (value) isNumOrLetters(rule, value, cbfn);
      cbfn();
    };
    /** 分类编码/开头标识验证输入内容是否是字母或数字 */
    let isNumOrLetter = (rule, value, cbfn) => {
      isNumOrLetters(rule, value, cbfn);
    };
    return {
      normalAddItem: {},
      dataSource: [],
      listIndustry: [],
      nameErrData: [],
      materialclassificationList: [], //选择器明细项
      treeData: [], //树型下拉框节点数据源
      materialIncrementalEnum: [], //下拉列表框数据源
      normaladd: [],
      materialDefaultAttribute: ["物料编码", "物料名称", "版本", "来源"],
      columns,
      registerForm: {
        name: "",
      },
      /** 表单验证规则 */
      rules: {
        name: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
        code: [{ required: true, validator: isNumOrLetter, trigger: "blur" }],
        openingLogo: [
          {
            required: true,
            validator: openingLogoValidator,
            trigger: "blur",
          },
        ],
      },
      ishide: false,
    };
  },
  methods: {
    /** 折叠面板切换事件 */
    collapseClick() {
      if (this.ishide) this.ishide = false;
    },
    /**
     * 添加项删除事件
     * @param {object} index 项索引
     */
    closeSquareClick(index) {
      var data = [...this.normaladd];
      if (this.normaladd.length == 1) {
        this.$message.error("选择项必须存在一项");
        return;
      }
      this.normaladd = data.filter((item) => item.index !== index);
      this.normalAddItem.extraProperties = this.normaladd;
    },
    /**
     * 物料自定义属性下拉选择 "选择" 显示事件
     * @param {object} record 物料自定义属性对象
     */
    handleChanges(record) {
      let obj = this.Codelist.find((i) => {
        return i.id === record.type;
      });
      let selectName = obj.codeName;
      if (selectName == "选择") {
        if (!record.extraProperties) {
          var data = { index: this.guid(), value: "" };
          record.extraProperties = [];
          record.extraProperties.push(data);
        }
        this.normaladd = record.extraProperties;
        this.normalAddItem = record;
        this.ishide = true;
      } else {
        record.extraProperties = null;
        this.normalAddItem = {};
        this.ishide = false;
      }
    },
    /**
     * 物料属性字段状态检验
     * @param {string} key 物料属性Key
     */
    getFildStatus(key) {
      const data = this.nameErrData.filter((item) => key === item.key)[0];
      if (data) {
        return data;
      } else {
        return {
          errorMsg: "",
          validateStatus: "success",
        };
      }
    },
    /**
     * 物料属性名称失去焦点事件
     * @param {object} record 物料属性
     */
    nameChange(record) {
      const { errorMsg, validateStatus } = validateNameValue(
        record.name,
        this.dataSource
      );
      let flag = true;
      this.nameErrData.forEach((val) => {
        if (val.key === record.key) {
          flag = false;
          (val.errorMsg = errorMsg), (val.validateStatus = validateStatus);
        }
      });
      if (flag) {
        var key = record.key;
        this.nameErrData.push({
          key,
          errorMsg,
          validateStatus,
        });
      }
    },
    /** 添加选择项 */
    addoptions() {
      var data = { index: this.guid(), value: "" };
      this.normaladd.push(data);
    },
    /** 保存事件 */
    addsorthandleOk() {
      var _this = this;
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          var parm = _this.registerForm;
          var data = [];
          var nameNullData = _this.dataSource.filter((item) => item.name == "");
          if (nameNullData.length > 0) {
            this.$message.error("物料属性字段存在名称未设值");
            return;
          }
          if (this.nameErrData.length > 0) {
            var isreturn = false;
            this.nameErrData.forEach((item) => {
              if (item.validateStatus == "error") {
                this.$message.error(item.errorMsg);
                isreturn = true;
                return;
              }
            });
            if (isreturn) return;
          }
          var err = "";
          _this.dataSource.forEach((item) => {
            var entity = {
              name: item.name,
              classify: item.classify,
              type: item.type,
              enabled: item.enabled,
              required: item.required,
            };
            var isNew = item.key.substring(item.key.length - 3);
            if (isNew !== "New") {
              entity.id = item.key;
            }
            if (item.extraProperties) {
              entity.extraProperties = [];
              var extraPropertiesData = item.extraProperties.filter(
                (item) => item.value == ""
              );
              if (extraPropertiesData.length > 0) {
                err = "物料属性" + item.name + "选择项存在空值";
                return;
              }
              item.extraProperties.forEach((ext) => {
                entity.extraProperties.push(ext.value);
              });
            }
            data.push(entity);
          });
          if (err !== "") {
            this.$message.error(err);
            return;
          }
          parm.attributes = data;
          updateMaterialClassification(parm)
            .then(() => {
              this.$emit("submited");
              this.$emit("categorynameQuery");
              this.getMaterialClassificationTreeData();
              this.$message.success("保存成功");
            })
            .catch(() => {
              this.$emit("submited");
              this.$message.error("保存失败");
            });
        }
      });
    },
    /**
     * 是否启用Change事件
     * @param {object} record 物料属性对象
     */
    enabledChange(record) {
      record.enabled = !record.enabled;
    },
    /**
     * 是否必填Change事件
     * @param {object} record 物料属性对象
     */
    requiredChange(record) {
      record.required = !record.required;
    },
    /**
     * 选择器选中项事件
     * @param {string} value Select选中项值
     */
    treeDataSelect(value, node, extra) {
      this.registerForm.parentID = value;
    },
    /**
     * 选择器下拉项是否可选择判断
     * @param {string} value 下拉框项
     */
    whenChangeMaterialIncrementalEnumdisabled(value) {
      var Enum = this.materialIncrementalEnum.find(
        (item) => item.id === this.incrementalTypeId
      );
      if (!Enum) return true;
      return Enum.value > value.value;
    },
    /**
     * 选择器选中项事件
     * @param {string} value Select选中项值
     */
    whenChangeMaterialIncrementalEnum(value) {
      this.registerForm.incrementalType = value.id;
    },
    /** 添加自定义字段 */
    addhang() {
      this.ishide = false;
      var data = {
        key: this.guid() + "New",
        name: "",
        classify: 1,
        type: this.Codelist ? this.Codelist[0].id : "",
        enabled: false,
        required: false,
        typeReadonly: false,
        enabledReadonly: false,
        requiredReadonly: false,
        isAdd: true,
      };
      this.dataSource.push(data);
    },
    /**
     * 删除物料自定义属性
     * @param {guid} value guid
     */
    outclick(value) {
      if (!value.isAdd) {
        this.$message.error("已保存物料属性字段不可删除，只能做修改");
        return;
      }
      if (value.classify === 1) {
        this.ishide = false;
        const data = [...this.dataSource];
        this.dataSource = data.filter((item) => item.key !== value.key);
        var nameErrData = [...this.nameErrData];
        this.nameErrData = nameErrData.filter((item) => item.key !== value.key);
      } else {
        this.$message.error("默认物料属性字段不可删除");
      }
    },
    /** 获取物料分类数据 */
    getMaterialClassificationTreeData() {
      getMaterialClassificationTreeData().then((res) => {
        this.materialclassificationList = res;
        this.treeData = this.getTreeData(this.materialclassificationList);
      });
    },
    /**
     * 递归生成树型下拉框数据源
     * @param {arrty} list 物料分类数据
     */
    getTreeData(list) {
      var treedata = [];
      list.forEach((item) => {
        var data = {
          id: item.id,
          pId: item.id,
          value: item.id,
          title: item.name,
          isLeaf: item.childMaterials.length > 0 ? false : true,
        };
        if (item.childMaterials.length > 0)
          data.children = this.getTreeData(item.childMaterials);
        treedata.push(data);
      });
      return treedata;
    },
    /** 获取默认物料属性数据 */
    getdatasource() {
      if (this.Codelist) {
        this.dataSource = [];
        var materialDefaultAttribute = this.materialDefaultAttribute;
        this.registerForm.attributes.forEach((item) => {
          var data = {
            key: item.id,
            name: item.name,
            classify: item.classify,
            type: item.type,
            enabled: item.enabled,
            required: item.required,
            typeReadonly: true,
            enabledReadonly: item.classify == 0 ? true : false,
            requiredReadonly:
              materialDefaultAttribute.indexOf(item.name) > -1 ? true : false,
          };
          if (item.extraProperties && item.extraProperties.unit) {
            data.extraProperties = [];
            item.extraProperties.unit.forEach((item) => {
              var unit = { index: this.guid(), value: item };
              data.extraProperties.push(unit);
            });
          }
          this.nameErrData = [];
          this.dataSource.push(data);
        });
      }
    },

    /** 生成唯一数值 */
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },

    /** 生成唯一guid */
    guid() {
      return (
        this.S4() +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        this.S4() +
        this.S4()
      );
    },
  },
  watch: {
    form: {
      handler() {
        this.registerForm = this.form;
        if (this.registerForm.id) {
          this.getdatasource();
        }
        this.ishide = false;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.AS-collapse ::v-deep.ant-collapse {
  border: none;
  background: #ffffff;
}

.AS-collapse ::v-deep.ant-collapse-content {
  border: none;
}

.AS-collapse ::v-deep.ant-collapse-item {
  border: none;
}

.AS-collapse ::v-deep.ant-collapse-header {
  border-bottom: 1px solid #d9d9d9;
}

.AS-collapse ul {
  padding-left: 0;
  overflow: hidden;
}

.AS-collapse ul li {
  list-style: none;
  float: left;
  width: 25%;
  text-align: center;
}

.AS-collapse ::v-deep .ant-checkbox-wrapper {
  margin-top: 5px;
}
.addOptions {
  border: 1px solid #d9d9d9;
  padding: 10px 15px;
  margin-top: 20px;
}
.addOptions span {
  color: #1890ff;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 5px;
  display: inline-block;
}
.addOptions .ant-input {
  margin-right: 10px;
}
.addOptions .ant-btn {
  float: right;
}

::v-deep.ant-table-thead > tr > th,
::v-deep.ant-table-tbody > tr > td {
  padding: 10px;
}

.addblock {
  margin-bottom: 10px;
}
</style>
