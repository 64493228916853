var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{staticClass:"rather-info",attrs:{"gutter":[24, 16]}},[_c('a-col',{attrs:{"span":24}},[_c('a-select',{staticStyle:{"width":"30%"},model:{value:(_vm.version1),callback:function ($$v) {_vm.version1=$$v},expression:"version1"}},_vm._l((_vm.dropDownList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('a-select',{staticStyle:{"width":"30%"},model:{value:(_vm.version2),callback:function ($$v) {_vm.version2=$$v},expression:"version2"}},_vm._l((_vm.dropDownList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onCompare}},[_vm._v("对比")])],1),_c('a-col',{attrs:{"span":12}},[_c('div',{staticClass:"version"},[(_vm.materialList.length > 0)?_c('h3',[_vm._v(" "+_vm._s(_vm.materialList[0].version)+"版本 ")]):_vm._e(),_c('p',[_c('span',{class:{
              success:
                _vm.materialList[0] &&
                _vm.materialList[0].name != _vm.materialList[1].name,
            }},[_vm._v("物料名称："+_vm._s(_vm.materialList[0] ? _vm.materialList[0].name : ""))])]),_c('p',[_c('span',{class:{
              success:
                _vm.materialList[0] &&
                _vm.materialList[0].classificationName !=
                  _vm.materialList[1].classificationName,
            }},[_vm._v("分类："+_vm._s(_vm.materialList[0] ? _vm.materialList[0].classificationName : ""))])]),_c('p',[_c('span',{class:{
              success:
                _vm.materialList[0] &&
                _vm.materialList[0].specification !=
                  _vm.materialList[1].specification,
            }},[_vm._v("规格型号："+_vm._s(_vm.materialList[0] ? _vm.materialList[0].specification : ""))])]),_c('p',[_c('span',{class:{
              success:
                _vm.materialList[0] &&
                _vm.materialList[0].specification != _vm.materialList[1].mainProcess,
            }},[_vm._v("主工艺："+_vm._s(_vm.materialList[0] ? _vm.materialList[0].mainProcess : ""))])]),_c('p',[_c('span',{class:{
              success:
                _vm.materialList[0] &&
                _vm.materialList[0].specification != _vm.materialList[1].texture,
            }},[_vm._v("材质："+_vm._s(_vm.materialList[0] ? _vm.materialList[0].texture : ""))])]),_c('p',[_c('span',{class:{
              success:
                _vm.materialList[0] &&
                _vm.materialList[0].specification != _vm.materialList[1].unitName,
            }},[_vm._v("单位："+_vm._s(_vm.materialList[0] ? _vm.materialList[0].unitName : ""))])]),_c('p',[_c('span',{class:{
              success:
                _vm.materialList[0] &&
                _vm.materialList[0].specification != _vm.materialList[1].description,
            }},[_vm._v("备注："+_vm._s(_vm.materialList[0] ? _vm.materialList[0].description : ""))])])]),_c('div',{staticClass:"single-info"},[_c('a-table',{attrs:{"columns":_vm.currentcolumns,"data-source":_vm.currentdata,"rowKey":function (record) { return record.id; },"childrenColumnName":"childBom","expanded-row-keys":_vm.expandedRowKeys,"bordered":""},on:{"update:expandedRowKeys":function($event){_vm.expandedRowKeys=$event},"update:expanded-row-keys":function($event){_vm.expandedRowKeys=$event}}},[_c('template',{slot:"title"},[_vm._v("BOM")])],2)],1)]),_c('a-col',{attrs:{"span":12}},[_c('div',{staticClass:"version"},[(_vm.materialList.length > 0)?_c('h3',[_vm._v(" "+_vm._s(_vm.materialList[1].version)+"版本 ")]):_vm._e(),_c('p',[_c('span',{class:{
              warning:
                _vm.materialList[1] &&
                _vm.materialList[0].name != _vm.materialList[1].name,
            }},[_vm._v("物料名称："+_vm._s(_vm.materialList[1] ? _vm.materialList[1].name : ""))])]),_c('p',[_c('span',{class:{
              warning:
                _vm.materialList[1] &&
                _vm.materialList[0].classificationName !=
                  _vm.materialList[1].classificationName,
            }},[_vm._v("分类："+_vm._s(_vm.materialList[1] ? _vm.materialList[1].classificationName : ""))])]),_c('p',[_c('span',{class:{
              warning:
                _vm.materialList[1] &&
                _vm.materialList[0].specification !=
                  _vm.materialList[1].specification,
            }},[_vm._v("规格型号："+_vm._s(_vm.materialList[1] ? _vm.materialList[1].specification : ""))])]),_c('p',[_c('span',{class:{
              warning:
                _vm.materialList[1] &&
                _vm.materialList[0].mainProcess != _vm.materialList[1].mainProcess,
            }},[_vm._v("主工艺："+_vm._s(_vm.materialList[1] ? _vm.materialList[1].mainProcess : ""))])]),_c('p',[_c('span',{class:{
              warning:
                _vm.materialList[1] &&
                _vm.materialList[0].texture != _vm.materialList[1].texture,
            }},[_vm._v("材质："+_vm._s(_vm.materialList[1] ? _vm.materialList[1].texture : ""))])]),_c('p',[_c('span',{class:{
              warning:
                _vm.materialList[1] &&
                _vm.materialList[0].unitName != _vm.materialList[1].unitName,
            }},[_vm._v("单位："+_vm._s(_vm.materialList[1] ? _vm.materialList[1].unitName : ""))])]),_c('p',[_c('span',{class:{
              warning:
                _vm.materialList[1] &&
                _vm.materialList[0].description != _vm.materialList[1].description,
            }},[_vm._v("备注："+_vm._s(_vm.materialList[1] ? _vm.materialList[1].description : ""))])])]),_c('div',{staticClass:"single-info"},[_c('a-table',{attrs:{"columns":_vm.choosecolumns,"data-source":_vm.choosedata,"rowKey":function (record) { return record.id; },"childrenColumnName":"childBom","expanded-row-keys":_vm.expandedRowKeys1,"bordered":""},on:{"update:expandedRowKeys":function($event){_vm.expandedRowKeys1=$event},"update:expanded-row-keys":function($event){_vm.expandedRowKeys1=$event}}},[_c('template',{slot:"title"},[_vm._v("BOM")])],2)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }