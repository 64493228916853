export default [
  {
    title: "物料编码",
    dataIndex: "fullCode",
    //width: 150,
    //fixed: "left",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "fullCode" },
    ellipsis: true,
  },
  {
    title: "物料名称",
    dataIndex: "name",
    //width: 210,
    //fixed: "left",
    ellipsis: true,
  },
  {
    title: "规格型号",
    dataIndex: "specification",
    ellipsis: true,
  },
  {
    title: "来源",
    dataIndex: "sourceText",
    ellipsis: true,
  },
  {
    title: "材质",
    dataIndex: "texture",
    ellipsis: true,
  },
  {
    title: "主工艺",
    dataIndex: "mainProcess",
    ellipsis: true,
  },
  {
    title: "分类",
    dataIndex: "classificationName",
    ellipsis: true,
  },
  {
    title: "单位",
    dataIndex: "unitName",
    ellipsis: true,
  },
  {
    title: "附件数",
    dataIndex: "attachments",
    ellipsis: true,
  },
  {
    title: "版本",
    dataIndex: "version",
    customRender: function (text) {
      return "V" + text + ".0";
    },
    ellipsis: true,
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right",
    align: "center",
  },
];
