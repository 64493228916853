<template>
  <div>
    <div class="left-tree">
      <a-input-search
        v-model="searchStr"
        placeholder="输入搜选项"
        @change="onChange"
      ></a-input-search>
      <!-- selectedKeys是选中项key的集合，expandedKeys是展开项key的集合 -->
      <a-tree
        v-model="checkedKeys"
        :treeData="gData"
        :selectedKeys="selectedKeys"
        :expandedKeys="expandedKeys"
        @expand="onExpand"
        :autoExpandParent="autoExpandParent"
        @select="OnSelect"
      >
        <template slot="title" slot-scope="item">
          <a-row>
            <a-col v-if="item.title.indexOf(searchValue) > -1" :span="20">
              {{ item.title.substr(0, item.title.indexOf(searchValue)) }}
              <span style="color: #1890ff">{{ searchValue }}</span>
              {{
                item.title.substr(
                  item.title.indexOf(searchValue) + searchValue.length
                )
              }}
            </a-col>
            <a-col v-else :span="20">{{ item.title }}</a-col>
            <a-col :span="4" class="op-btn">
              <a-icon type="edit" @click="addsortModal(item)" />
              <a-icon type="delete" @click="showConfirm(item)" />
            </a-col>
          </a-row>
        </template>
      </a-tree>
    </div>
    <!-- 新增分类 -->
    <editsort
      :visible="addsortCtr.addsortvisible"
      :form="addsortCtr.form"
      :incrementalTypeId="addsortCtr.incrementalTypeId"
      @categorynameQuery="getDataList"
      @submited="
        () => {
          this.addsortCtr.addsortvisible = false;
          this.$refs['sortTree'].getMaterialClassificationTreeData();
        }
      "
      @cancel="
        () => {
          this.addsortCtr.addsortvisible = false;
        }
      "
      ref="sortTree"
    ></editsort>
  </div>
</template>
<script>
// 新增分类
import editsort from "./editsort";

import {
  getMaterialClassificationTreeData,
  getMaterialClassification,
  deleteMaterialClassification,
} from "@/api/materialclassification";
const gData = [];
const dataList = [];
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.key;
    const title = node.title;
    dataList.push({ key, title: title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export default {
  components: {
    editsort,
  },
  data() {
    return {
      expandedKeys: [],
      backupsExpandedKeys: [],
      autoExpandParent: false,
      checkedKeys: [],
      selectedKeys: [],
      searchValue: "",
      gData,
      searchStr: "",
      // 编辑分类
      addsortCtr: {
        form: {},
        addsortvisible: false,
        incrementalTypeId: "",
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 编辑分类
    addsortModal(item) {
      getMaterialClassification(item.value).then((res) => {
        this.addsortCtr.form = res;
        this.addsortCtr.addsortvisible = true;
        this.addsortCtr.incrementalTypeId = res.incrementalType;
      });
    },
    //左侧树状图删除
    showConfirm(item) {
      let _this = this;
      this.$confirm({
        title: "确认删除" + item.title + "吗?",
        onOk() {
          let cancelId = item.key;
          deleteMaterialClassification(cancelId).then((res) => {
            if (res.error) {
              _this.$message.success(res.error.message);
            } else {
              _this.$message.success("删除成功");
              _this.getDataList();
            }
          });
        },
        onCancel() {},
      });
    },
    onChange(e) {
      const value = e.target.value;
      let _this = this;
      const expandedKeys = dataList
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return _this.getParentKey(item.key, _this.gData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    getDataList() {
      const _this = this;
      getMaterialClassificationTreeData()
        .then(function (result) {
          _this.gData = _this.handlerData(result);
          generateList(_this.gData);
        })
        .catch(function (error) {});
    },
    handlerData(tree) {
      return tree.map((item) =>
        Object.assign({
          children: this.handlerData(item.childMaterials),
          key: item.id,
          value: item.id,
          title: item.name,
          attr: item.attributes,
          scopedSlots: { title: "title" },
        })
      );
    },
    onSearch() {
      var vm = this;
      //添加这行代码是为了只点击搜索才触发
      vm.searchValue = vm.searchStr;
      //如果搜索值为空，则不展开任何项，expandedKeys置为空
      //如果搜索值不位空，则expandedKeys的值要为搜索值的父亲及其所有祖先
      if (vm.searchValue === "") {
        vm.expandedKeys = [];
      } else {
        //首先将展开项与展开项副本置为空
        vm.expandedKeys = [];
        vm.backupsExpandedKeys = [];
        //获取所有存在searchValue的节点
        let candidateKeysList = vm.getkeyList(vm.searchValue, vm.gData, []);
        //遍历满足条件的所有节点
        candidateKeysList.map((item) => {
          //获取每个节点的母亲节点
          var key = vm.getParentKey(item, vm.gData);
          //当item是最高一级，父key为undefined，将不放入到数组中
          //如果母亲已存在于数组中，也不放入到数组中
          if (key && !vm.backupsExpandedKeys.some((item) => item === key))
            vm.backupsExpandedKeys.push(key);
        });
        let length = this.backupsExpandedKeys.length;
        for (var i = 0; i < length; i++) {
          vm.getAllParentKey(vm.backupsExpandedKeys[i], vm.gData);
        }
        vm.expandedKeys = vm.backupsExpandedKeys.slice();
      }
    },
    //获取节点中含有value的所有key集合
    getkeyList(value, tree, keyList) {
      //遍历所有同一级的树
      for (let i = 0; i < tree.length; i++) {
        let node = tree[i];

        //如果该节点存在value值则push
        if (node.title.indexOf(value) > -1) {
          keyList.push(node.key);
        }
        //如果拥有孩子继续遍历
        if (node.children) {
          this.getkeyList(value, node.children, keyList);
        }
      }
      //因为是引用类型，所有每次递归操作的都是同一个数组
      return keyList;
    },
    getParentKey(key, tree) {
      let parentKey;
      for (var i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    //获取该节点的所有祖先节点
    getAllParentKey(key, tree) {
      var parentKey;
      if (key) {
        //获得父亲节点
        parentKey = this.getParentKey(key, tree);
        if (parentKey) {
          //如果父亲节点存在，判断是否已经存在于展开列表里，不存在就进行push
          if (!this.backupsExpandedKeys.some((item) => item === parentKey)) {
            this.backupsExpandedKeys.push(parentKey);
          }
          //继续向上查找祖先节点
          this.getAllParentKey(parentKey, tree);
        }
      }
    },
    onExpand(expandedKeys) {
      //用户点击展开时，取消自动展开效果
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      console.log("onCheck", checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    OnSelect(selectedKeys, info) {
      selectedKeys = [info.node.$options.propsData.eventKey];
      console.log("onSelect", info);
      this.selectedKeys = selectedKeys;
      this.$emit("postKey", selectedKeys[0]);
      console.log(selectedKeys[0]);
    },
  },
};
</script>
<style lang="scss" scoped>
.left-tree {
  padding: 20px;
  background: #ffffff;
  margin-top: 20px;
  height: calc(100vh - 90px);
  overflow: auto;
}
.op-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 130px;
  display: inline-block;
}

.op-btn{
  display: none;
}

.op-btn .anticon-edit {
  margin-right: 5px;
}

.op-btn .anticon {
  color: #1890ff;
}

.left-tree .ant-tree li:hover .op-btn{
  display: block;
}
</style>
