<template>
  <div>
    <a-row class="rather-info" :gutter="[24, 16]">
      <a-col :span="24">
        <a-select style="width: 30%" v-model="version1">
          <a-select-option
            v-for="item in dropDownList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select style="width: 30%" v-model="version2">
          <a-select-option
            v-for="item in dropDownList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-button type="primary" @click="onCompare">对比</a-button>
      </a-col>
      <a-col :span="12">
        <div class="version">
          <h3 v-if="materialList.length > 0">
            {{ materialList[0].version }}版本
          </h3>
          <p>
            <span
              v-bind:class="{
                success:
                  materialList[0] &&
                  materialList[0].name != materialList[1].name,
              }"
              >物料名称：{{ materialList[0] ? materialList[0].name : "" }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                success:
                  materialList[0] &&
                  materialList[0].classificationName !=
                    materialList[1].classificationName,
              }"
              >分类：{{
                materialList[0] ? materialList[0].classificationName : ""
              }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                success:
                  materialList[0] &&
                  materialList[0].specification !=
                    materialList[1].specification,
              }"
              >规格型号：{{
                materialList[0] ? materialList[0].specification : ""
              }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                success:
                  materialList[0] &&
                  materialList[0].specification != materialList[1].mainProcess,
              }"
              >主工艺：{{
                materialList[0] ? materialList[0].mainProcess : ""
              }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                success:
                  materialList[0] &&
                  materialList[0].specification != materialList[1].texture,
              }"
              >材质：{{ materialList[0] ? materialList[0].texture : "" }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                success:
                  materialList[0] &&
                  materialList[0].specification != materialList[1].unitName,
              }"
              >单位：{{ materialList[0] ? materialList[0].unitName : "" }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                success:
                  materialList[0] &&
                  materialList[0].specification != materialList[1].description,
              }"
              >备注：{{
                materialList[0] ? materialList[0].description : ""
              }}</span
            >
          </p>
        </div>
        <div class="single-info">
          <a-table
            :columns="currentcolumns"
            :data-source="currentdata"
            :rowKey="(record) => record.id"
            childrenColumnName="childBom"
            :expanded-row-keys.sync="expandedRowKeys"
            bordered
          >
            <template slot="title">BOM</template>
          </a-table>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="version">
          <h3 v-if="materialList.length > 0">
            {{ materialList[1].version }}版本
          </h3>
          <p>
            <span
              v-bind:class="{
                warning:
                  materialList[1] &&
                  materialList[0].name != materialList[1].name,
              }"
              >物料名称：{{ materialList[1] ? materialList[1].name : "" }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                warning:
                  materialList[1] &&
                  materialList[0].classificationName !=
                    materialList[1].classificationName,
              }"
              >分类：{{
                materialList[1] ? materialList[1].classificationName : ""
              }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                warning:
                  materialList[1] &&
                  materialList[0].specification !=
                    materialList[1].specification,
              }"
              >规格型号：{{
                materialList[1] ? materialList[1].specification : ""
              }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                warning:
                  materialList[1] &&
                  materialList[0].mainProcess != materialList[1].mainProcess,
              }"
              >主工艺：{{
                materialList[1] ? materialList[1].mainProcess : ""
              }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                warning:
                  materialList[1] &&
                  materialList[0].texture != materialList[1].texture,
              }"
              >材质：{{ materialList[1] ? materialList[1].texture : "" }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                warning:
                  materialList[1] &&
                  materialList[0].unitName != materialList[1].unitName,
              }"
              >单位：{{ materialList[1] ? materialList[1].unitName : "" }}</span
            >
          </p>
          <p>
            <span
              v-bind:class="{
                warning:
                  materialList[1] &&
                  materialList[0].description != materialList[1].description,
              }"
              >备注：{{
                materialList[1] ? materialList[1].description : ""
              }}</span
            >
          </p>
        </div>
        <div class="single-info">
          <a-table
            :columns="choosecolumns"
            :data-source="choosedata"
            :rowKey="(record) => record.id"
            childrenColumnName="childBom"
            :expanded-row-keys.sync="expandedRowKeys1"
            bordered
          >
            <template slot="title">BOM</template>
          </a-table>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { getDropdownList, getVersionList } from "@/api/material.js";
import { getChildBom } from "@/api/bom.js";

// 当前历史版本
const currentcolumns = [
  {
    title: "物料编码",
    dataIndex: "materialCode",
  },
  {
    title: "物料名称",
    dataIndex: "materialName",
  },
  {
    title: "规格型号",
    dataIndex: "specification",
  },
  {
    title: "版本",
    dataIndex: "versionText",
  },
];

const currentdata = [];

// 选择对比的版本
const choosecolumns = [
  {
    title: "物料编码",
    dataIndex: "materialCode",
  },
  {
    title: "物料名称",
    dataIndex: "materialName",
  },
  {
    title: "规格型号",
    dataIndex: "specification",
  },
  {
    title: "版本",
    dataIndex: "versionText",
  },
];

const choosedata = [];
export default {
  name: "VersionCompare",
  props: {
    record: Object,
  },
  data() {
    return {
      currentdata,
      currentcolumns,
      choosedata,
      choosecolumns,
      dropDownList: [],
      version1: "",
      version2: "",
      materialList: [],

      expandedRowKeys: [],
      expandedRowKeys1: [],
    };
  },
  watch: {
    record: {
      immediate: true,
      deep: false,
      handler: function (val) {
        this.getDrops(val.classificationID, val.fullCode);
      },
    },
  },
  methods: {
    getDrops: function (classificationID, fullCode) {
      getDropdownList({
        classificationID: classificationID,
        fullCode: fullCode,
      }).then((res) => {
        this.dropDownList = res;
      });
    },
    onCompare: function () {
      let _this = this;
      getVersionList({ ids: [this.version1, this.version2] }).then((res) => {
        var _data = res;
        if (_this.version1 == _this.version2) _data = _data.concat(res);
        if (res.length == 2 && res[0].id != _this.version1)
          _data = res.reverse();
        this.materialList = _data;
      });

      this.getChildBoms();
    },

    getChildBoms: function () {
      getChildBom({ materialId: this.version1 }).then((res) => {
        this.currentdata = res;
        this.expandedRowKeys.push(res[0].id);
      });

      getChildBom({ materialId: this.version2 }).then((res) => {
        this.choosedata = res;
        this.expandedRowKeys1.push(res[0].id);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rather-info .ant-select {
  margin-right: 20px;
}

.version {
  margin-top: 20px;
}

.version h3 {
  text-align: center;
  margin-bottom: 20px;
}

.version p {
  margin-bottom: 15px;
}

.single-info ::v-deep.ant-table-title {
  text-align: center;
}
span.success {
  color: green;
}
span.warning {
  color: red;
}
</style>
