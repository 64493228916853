<template>
  <div class="importsort">
    <h3>选择文件</h3>
    <a-upload-dragger
      name="file"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :file-list="fileList"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        可以直接拖拽上传，或者
        <span>选择文件</span>、<span>选择文件夹</span>进行上传
      </p>
    </a-upload-dragger>

    <a-row class="info-row">
      <a-col :span="6">
        <h3>导入明细</h3>
      </a-col>
      <a-col :span="12">
        <span>物料分类：</span>
        <a-tree-select
          v-model="classificationID"
          :tree-data="getSort"
          placeholder="请选择物料分类"
          :replaceFields="replaceFields"
          @change="SortChange"
          style="width: 50%"
          :dropdown-style="{ maxHeight: '200px', overflow: 'auto' }"
        >
        </a-tree-select>
      </a-col>
      <a-col :span="6" style="text-align: right">
        <a-button type="primary" @click="templateDownload">模板下载</a-button>
        <a-button type="primary" @click="clearClick">清空列表</a-button>
      </a-col>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="importMaterielData"
      :rowKey="(row, index) => index"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="operation" slot-scope="text, record, index">
        <a-space :size="20">
          <a
            ><a-icon
              type="delete"
              :style="{ fontSize: '26px' }"
              @click="rowClick(index)"
          /></a>
        </a-space>
      </template>
    </a-table>
  </div>
</template>
<script>
import { getMaterialTemplateList } from "@/api/material";
import { getMaterialClassificationTreeData } from "@/api/materialclassification";
const url = process.env.VUE_APP_API_BASE_URL;
import store from "@/store";
export default {
  props: {
    materielvisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      importMaterielData: [],
      SortDefaultValue: "",
      classificationID: null,
      columns: [
        {
          title: "序号",
          dataIndex: "order",
          scopedSlots: { customRender: "no" },
        },
        {
          title: "物料名称",
          dataIndex: "name",
        },
        {
          title: "规格型号",
          dataIndex: "specification",
        },
        {
          title: "版本",
          dataIndex: "version",
        },
        {
          title: "分类",
          dataIndex: "classificationName",
        },
        {
          title: "主工艺",
          dataIndex: "mainProcess",
        },
        {
          title: "材质",
          dataIndex: "texture",
        },
        {
          title: "单位",
          dataIndex: "unitName",
        },
        {
          title: "描述",
          dataIndex: "description",
        },
        {
          title: "来源",
          dataIndex: "sourceName",
        },
        /*   {
          title: "删除",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        }, */
      ],
      columnheader: [
        {
          title: "序号",
          dataIndex: "order",
          scopedSlots: { customRender: "no" },
        },
        {
          title: "物料名称",
          dataIndex: "name",
        },
        {
          title: "规格型号",
          dataIndex: "specification",
        },
        {
          title: "版本",
          dataIndex: "version",
        },
        {
          title: "分类",
          dataIndex: "classificationName",
        },
        {
          title: "主工艺",
          dataIndex: "mainProcess",
        },
        {
          title: "材质",
          dataIndex: "texture",
        },
        {
          title: "单位",
          dataIndex: "unitName",
        },
        {
          title: "描述",
          dataIndex: "description",
        },
        {
          title: "来源",
          dataIndex: "sourceName",
        },
        /*   {
          title: "删除",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        }, */
      ],
      fileList: [],
      getSort: [],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      replaceFields: {
        children: "childMaterials",
        key: "id",
        value: "id",
        title: "name",
      },
    };
  },
  watch: {
    materielvisible(val) {
      if (!val) {
        this.importMaterielData = [];
        this.classificationID = null;
      } else {
        this.getSortDownList();
      }
    },
  },
  created() {
    this.getSortDownList();
  },
  methods: {
    //分页
    handleTableChange(pagination) {
      console.log(pagination);
      this.pagination.current = pagination.current; //当前第几页
      this.pagination.pageSize = pagination.pageSize;
      this.SkipCount = (pagination.current - 1) * pagination.pageSize; //从总数据的第几条数据开始取数据
      this.MaxResultCount = pagination.pageSize; //一页多少条
    },

    beforeUpload(file) {
      console.log("file", file);

      this.fileList = [...this.fileList, file];
      return false;
    },
    rowClick(index) {
      let pageSize = this.pagination.pageSize;
      let current = this.pagination.current - 1;
      let num = current * pageSize + index;
      this.importMaterielData.splice(num, 1);

      this.fileList.splice(num, 1);

      this.pagination.current = 1;
      this.$emit("uploadmaterialSuccess", this.importMaterielData);
    },

    handleChange(info) {
      console.log("info", info);
      const formData = new FormData();
      formData.append("file", info.file);
      getMaterialTemplateList(formData).then((res) => {
        console.log("addMaterial", res);
        console.log(this.columnheader);
        console.log(this.columns);
        this.columns.splice.apply(this.columns, [
          0,
          this.columns.length,
          ...this.columnheader,
        ]);
        console.log(this.columnheader);
        console.log(this.columns);
        res[0].customeProperties.forEach((el) => {
          this.columns.push({
            title: el.name,
            dataIndex: el.name,
          });
        });
        this.columns.push({
          title: "删除",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        });
        console.log(this.columns);
        res.forEach((el) => {
          el.customeProperties.forEach((item) => {
            var name = item.name;
            var value = item.value;
            el[name] = value;
          });
        });
        this.importMaterielData = res.map((item, index) => {
          return Object.assign(item, { order: index + 1 });
        });
        this.$emit("uploadmaterialSuccess", res);
        this.$emit("materialClassificationId", this.classificationID);
        console.log("res", res);
        console.log(this.classificationID);
      });
    },
    clearClick() {
      console.log("clearClick");
      this.importMaterielData = [];
      this.columns.splice.apply(this.columns, [
        0,
        this.columns.length,
        ...this.columnheader,
      ]);
    },

    //编辑分类下拉
    getSortDownList(params) {
      const _this = this;
      getMaterialClassificationTreeData(params)
        .then(function (result) {
          console.log(result);
          _this.getSort = result;
        })
        .catch(function (error) {});
    },

    //分类下拉框取值
    SortChange(value) {
      console.log(value);
      this.classificationID = value;
      this.$emit("materialClassificationId", this.classificationID);
    },

    // 模板下载
    templateDownload() {
      var materialClassificationId = this.classificationID;
      if (
        materialClassificationId == "" ||
        materialClassificationId == null ||
        materialClassificationId == undefined
      ) {
        this.$message.error("请选中物料分类");
      } else {
        this.$emit("materialClassificationId", this.classificationID);
        const token = store.getters.getToken;
        const c_c = store.getters.getUserInfo.companyId;
        const __tenant = store.getters.getUserInfo.tenantId;
        const sessionId = store.getters.getSessionId;
        window.open(
          `${url}/api/production/material/getMaterialExcel?materialClassificationId=` +
            materialClassificationId +
            "&access_token=" +
            token +
            "&__tenant=" +
            __tenant +
            "&c_c=" +
            c_c +
            "&c_s=" +
            sessionId +
            "&negotiateVersion=1"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ant-upload-text span {
  color: #1890ff;
}

.importsort h3 {
  margin-bottom: 20px;
}

.info-row {
  margin: 20px 0;
}

.info-row .ant-btn {
  margin-right: 15px;
}

.info-row .ant-btn:last-child {
  margin-right: 0;
}

.info-row .ant-col-12:nth-child(3) {
  text-align: right;
}
</style>
