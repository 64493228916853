import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";

/**
 * 查询物料集合
 * @param {*} param
 * @returns
 */
export const getMaterialList = (param) =>
  getRequest(`/production/material/getList`, param);
/**
 * 查询单个物料明细
 * @param {物料id} param
 * @returns
 */
export const getMaterial = (param) =>
  getRequest(`/production/material/${param}`);
/**
 * 新建物料
 * @param {*} param
 * @returns
 */
export const createMaterial = (param) =>
  postRequest(`/production/material/create`, param);
/**
 * 删除物料
 * @param {*} param
 * @returns
 */
export const deleteMaterial = (param) =>
  deleteRequest(`/production/material/delete`, param);
/**
 * 更新物料
 * @param {*} param
 * @returns
 */
export const updateMaterial = (param) =>
  updateRequest(`/production/material/update`, param);
/**
 * 获取物料最新物料编码
 * @param {*} param
 * @returns
 */
export const getLatestCode = (param) =>
  getRequest(`/production/material/getLatestCode`, param);
/**
 * 导入物料
 * @param {*} param
 * @returns
 */
export const addMaterial = (param) =>
  postRequest(`/production/material/addMaterial`, param);
/**
 * 导入物料模板下载
 * @param {*} param
 * @returns
 */
export const getMaterialExcel = (param) =>
  getRequest(`/production/material/getMaterialExcel`, param);
/**
 * 历史版本下拉
 * @param {*} param
 * @returns
 */
export const getDropdownList = (param) =>
  getRequest(`/production/material/getDropdownList`, param);
/**
 * 历史版本物料
 * @param {*} param
 * @returns
 */
export const getVersionList = (param) =>
  getRequest(`/production/material/getVersionList`, param);

/** 获取任务可关联列表
 */
export const getTaskList = (param) =>
  postRequest(`/production/material/task`, param);
/**
 * 物料模板上传解析
 * @param {*} param
 * @returns
 */
export const getMaterialTemplateList = (param) =>
  postRequest(`/production/material/getMaterialTemplateList`, param);

/**附件列表 */
export const getAttachmentList = (param) =>
  getRequest(`/production/material/getFigureDocumentList`, param);

/**关联物料 */
export const bindAttachmentList = (param) =>
  postRequest(`/production/material/link/bind`, param);

/**获取关联物料图文档ID集合 */
export const getFigureDocuemtIdList = (param) =>
  getRequest(`/production/material/link/getFigureDocumentIDs`, param);

/**获取任务列表用的版本 */
export const getTaskVersions = (param) =>
  postRequest(`/production/material/task/versions`, param);
